import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductViewModel } from '@core/models/tapp-order/view-model/product/product.view.model';
import { PlaceService } from '../../../../../services/place.service';

@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
})
export class ProductItemComponent {
  /* Required inputs */
  @Input('data') product!: ProductViewModel;

  /* Optional inputs */
  @Input() buttonVisible: boolean = false;
  @Input() buttonLabel: string = '';

  @Input() priceVisible: boolean = true;

  @Output() onAdd: EventEmitter<void> = new EventEmitter<void>();

  public isPizzaImageShiftedLeft: boolean = false;

  constructor(private placeService: PlaceService) {
    this.placeService.getPlace().subscribe((place) => {
      this.isPizzaImageShiftedLeft = place.themeSettings.isPizzaImageShiftedLeft;
    });
  }
}
