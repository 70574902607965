import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { LinkViewModel } from '@core/models/tapp-order/view-model/place/link.view.model';
import { PlaceViewModel } from '@core/models/tapp-order/view-model/place/place.view.model';
import { AuthService } from '@core/services/auth.service';
import { LoginModalComponent } from '@shared/login-modal/login-modal.component';
import { PlaceService } from '../../services/place.service';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  public hasPlacesList: boolean = false;
  public links: LinkViewModel[] = [];

  constructor(
    public dialog: MatDialog,
    public authService: AuthService,
    private placeService: PlaceService,
    private router: Router,
    public themeService: ThemeService,
  ) {
    this.placeService.getPlaces().subscribe((places: PlaceViewModel[]) => {
      if (places.filter((place: PlaceViewModel) => place.onlineOrderActive).length > 1) {
        this.hasPlacesList = true;
      }

      if (!this.placeService.getPlaceId()) {
        const place = places.find((place: PlaceViewModel) => place.links && place.links.length > 0);
        if (place) {
          this.links = place.links;
        }
      }
    });
  }

  ngOnInit(): void {
    this.links = [];
    this.initLinks();
    this.placeService.updatePlaceSubject$.subscribe(() => {
      this.initLinks();
    });
  }

  private initLinks(): void {
    if (this.placeService.getPlaceId()) {
      this.placeService.getPlace().subscribe((place: PlaceViewModel) => {
        this.links = place.links;
      });
    }
  }

  openDialog(): void {
    this.dialog.open(LoginModalComponent, {
      panelClass: 'login-form-container',
    });
  }

  get tableId() {
    return sessionStorage.getItem('tableId');
  }

  get qrMenuMode() {
    return sessionStorage.getItem('qrMenuMode') == 'true' ? true : false;
  }
}
