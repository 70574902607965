<app-card
  [title]="product.name"
  [description]="product.description | removeDots"
  [image]="product.image?.url"
  [isImageShiftedLeft]="product.productType === 'pizza' && isPizzaImageShiftedLeft"
  [price]="product.price"
  [priceTextFromVisible]="product.hasPriceFrom"
  [buttonVisible]="buttonVisible"
  [buttonLabel]="buttonLabel"
  [attributes]="product.attributes"
  [priceVisible]="priceVisible"
  (onButtonClick)="onAdd.emit()"
></app-card>
