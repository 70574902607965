import { Exclude, Expose } from 'class-transformer';
@Exclude()
export class ThemeSettingsApiModel {
  @Expose()
  public themeMode: string;

  @Expose()
  public primaryBorderRadius: number;

  @Expose()
  public primaryColor: string;

  @Expose()
  public isPizzaImageShiftedLeft: boolean = true;
}
