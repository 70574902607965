import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { SettingsApiModel } from '@core/models/tapp-order/api-model/core/settings.api.model';

@Injectable({
  providedIn: 'root',
})
export class SettingsHttpService {
  constructor(private http: HttpClient) {}

  getSettings(): Observable<SettingsApiModel> {
    return this.http
      .get<SettingsApiModel>('/core/settings/')
      .pipe(map((result) => plainToClass(SettingsApiModel, result as object)));
  }
}
