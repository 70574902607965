import { AbstractViewModel } from '../../../abstract.view.model';
import { SettingsApiModel } from '@core/models/tapp-order/api-model/core/settings.api.model';

export class SettingsViewModel extends AbstractViewModel<SettingsApiModel> {
  public chatBotCode: string;

  constructor(protected apiModel: SettingsApiModel = new SettingsApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.chatBotCode = this.apiModel.chatBotCode;
  }

  toApiModel(): SettingsApiModel {
    return undefined;
  }
}
