import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { SettingsHttpService } from '@core/http/tapp-order/core/settings.http.service';
import { SettingsApiModel } from '@core/models/tapp-order/api-model/core/settings.api.model';
import { SettingsViewModel } from '@core/models/tapp-order/view-model/core/settings.view.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SettingsDataProvider {
  constructor(private httpService: SettingsHttpService) {}

  public getSettings(): Observable<SettingsViewModel> {
    return this.httpService.getSettings().pipe(map((result: SettingsApiModel) => new SettingsViewModel(result)));
  }
}
